import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ServiceStyle'
import ServiceCard from '../Cards/ServiceCard'
import { projects } from '../../data/constant'


const Service = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container id="service">
      <Wrapper>
        <Title>Our Services</Title>
        <Desc>
          We offer wide range of services. From web apps to android apps. Here are some of our services.
        </Desc>
        <ToggleButtonGroup >
          {/* {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider /> */}
         
         
          {/* {toggle === 'android app' ?
            <ToggleButton active value="android app" onClick={() => setToggle('android app')}>Wordpress Websites</ToggleButton>
            :
            <ToggleButton value="android app" onClick={() => setToggle('android app')}>Wordpress Websites</ToggleButton>
          }
          <Divider /> */}

          {/* {toggle === 'web app' ?
            <ToggleButton active value="web app" onClick={() => setToggle('web app')}>WEB APP'S</ToggleButton>
            :
            <ToggleButton value="web app" onClick={() => setToggle('web app')}>WEB APP'S</ToggleButton>
          }
          <Divider /> */}
           {/* {toggle === 'Wordpress Website' ?
            <ToggleButton active value="android app" onClick={() => setToggle('android app')}>Wordpress Website</ToggleButton>
            :
            <ToggleButton value="android app" onClick={() => setToggle('android app')}>Wordpress Website</ToggleButton>
          }
          <Divider /> */}
         
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ServiceCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ServiceCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))} 
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Service