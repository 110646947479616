import React from 'react';
import './index.css';
import styled from 'styled-components';


// import images from './images';
// const Education = () => {
//     return (

//eslint-disable jsx-a11y/anchor-is-valid 
// import React from "react";

function Education () {
  let message = `There are many variations of passages of Lorem Ipsum available but the \n majority have suffered alteration in some injected humour.`;
  return (
    <div id="education">
    <section class="section-white">

      <div class="container">

        <div class="row">

          <div class="col-md-12 text-center">

            <h2 class="section-title">The Team Behind Cyber Creative</h2>

            <p class="section-subtitle">{message}</p>

          </div>




          <div class="col-sm-3 col-md-3">

            <div class="team-item">

              <img src="https://images.unsplash.com/photo-1694428878250-f1fb2bdb3c6f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80" class="team-img" alt="pic" />
              <h3>Ankith Kedar</h3>
              <div class="team-info"><p>Director</p></div>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id, nulla consectetur adipisci porro eius aliquam? Eum minus blanditiis atque doloribus.</p>

              <ul class="team-icon">

                <li><a href="#" class="twitter">
                  <i class="fa fa-twitter"></i>
                </a></li>

                <li><a href="#" class="pinterest">
                  <i class="fa fa-pinterest"></i>
                </a></li>

                <li><a href="#" class="facebook">
                  <i class="fa fa-facebook"></i>
                </a></li>

                <li><a href="#" class="dribble">
                  <i class="fa fa-dribbble"></i>
                </a></li>

              </ul>


            </div>
          </div>

          <div class="col-sm-3 col-md-3">

            <div class="team-item">

              <img src="https://images.unsplash.com/photo-1694681014694-cd7c077a1edf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80" class="team-img" alt="pic" />

              <h3>Gaurav Mahale</h3>

              <div class="team-info"><p>Developer</p></div>

              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi, quam error. Quasi illo, debitis maiores maxime quas accusamus est soluta?</p>

              <ul class="team-icon">

                <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>

                <li><a href="#" class="pinterest"><i class="fa fa-pinterest"></i></a></li>

                <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>

                <li><a href="#" class="dribble"><i class="fa fa-dribbble"></i></a></li>

              </ul>

            </div>

          </div>

          <div class="col-sm-3 col-md-3">

            <div class="team-item">

              <img src="https://images.unsplash.com/photo-1694429270394-8a6bd7538b98?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80" class="team-img" alt="pic" />
              <h3>Afshan Khan</h3>
              <div class="team-info"><p> Developer</p></div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur sit ducimus neque. Unde rerum, enim dicta accusantium </p>

              <ul class="team-icon">

                <li><a href="#" class="twitter">
                  <i class="fa fa-twitter"></i>
                </a></li>

                <li><a href="#" class="pinterest">
                  <i class="fa fa-pinterest"></i>
                </a></li>

                <li><a href="#" class="facebook">
                  <i class="fa fa-facebook"></i>
                </a></li>

                <li><a href="#" class="dribble">
                  <i class="fa fa-dribbble"></i>
                </a></li>

              </ul>


            </div>
          </div>

          <div class="col-sm-3 col-md-3">

            <div class="team-item">

              <img src="https://images.unsplash.com/photo-1694429072794-1deb1073f482?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80" class="team-img" alt="pic" />

              <h3>Piyaas Paul</h3>

              <div class="team-info"><p>Developer</p></div>

              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima quae quis illo ut eveniet dicta saepe cumque, et officiis consequuntur!
              </p>


              <ul class="team-icon">

                <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>

                <li><a href="#" class="pinterest"><i class="fa fa-pinterest"></i></a></li>

                <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>

                <li><a href="#" class="dribble">
                  <i class="fa fa-dribbble"></i>
                </a></li>

              </ul>

            </div>

          </div>


        </div>

      </div>

    </section>
    </div>
  )
 
}

export default Education;
